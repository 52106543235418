<template>
  <div>
    <el-row
      :gutter="1"
      type="flex"
      justify="center"
      v-if="dialogStatus === 'create'"
    >
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }">
        <hr style="border: 0.5px solid blue" />
        <el-form
          class="mgt16"
          ref="form"
          :model="form"
          :inline="true"
          :rules="rules"
          label-position="center"
          label-width="100px"
        >
          <el-form-item label="通知标题：" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="通知内容：" prop="content" class="label1">
            <el-input
              v-model="form.content"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="接收人员：" prop="studentname" class="label1">
            <el-input v-model="form.studentname"></el-input>
          </el-form-item>
          <!-- <el-form-item label="发送部门:" prop="senddepartment">
            <el-select
              class="item"
              v-model="form.departmentid"
              filterable
              allow-create
              default-first-option
              placeholder="请选择"
            >
              <el-option
                v-for="item in detailList"
                :key="item.id"
                :label="item.senddepartment"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
        <div class="dialog-footer align-center pdb16">
          <el-button @click="cancel()"> 取消 </el-button>
          <el-button type="primary" @click="createData"> 确定 </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="detail" v-else>
      <el-col :span="24"
        ><div class="grid-content">通知标题：{{ rowData.title }}</div></el-col
      >
      <el-col :span="24"
        ><div class="grid-content bg-purple">
          通知内容：{{ rowData.content }}
        </div></el-col
      >
      <el-col :span="24"
        ><div class="grid-content bg-purple">
          接收人员：{{ rowData.studentname }}
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
import * as api from "@/api/users";
export default {
  components: {},
  props: {
    // 带过来的数据
    rowData: {
      type: Object,
      default: () => {},
    },
    // 判断是详情还是创建
    dialogStatus: {
      type: Number,
    },
  },
  data() {
    return {
      starttime: "",
      endtime: "",
      options: [],
      detailList: [],
      value: [],
      readonly: true,
      form: {},
      rules: {
        category: [{ required: true, trigger: "blur" }],
        content: [
          { required: true, message: "通知内容不能为空", trigger: "blur" },
        ],
      },
      typeList: [],
      roleList: [],
      userTypeList: [],
    };
  },
  computed: {},
  methods: {
    cancel() {
      this.$emit("changeDialogFormVisible", false);
    },
    getTrue() {
      console.log("true");
      return true;
    },
    getFalse() {
      return false;
    },
    createData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          api.addNews(this.form).then((res) => {
            console.log(res);
            this.$emit("changeDialogFormVisible", false);
            this.$emit("goGetList");
          });
        }
      });
    },
    updateData() {
      console.log(this.form);
      //console.log("修改", this.dialogStatus);
      api.updateNews(this.form).then((res) => {
        console.log(res);
        this.$emit("changeDialogFormVisible", false);
      });
    },
    addNew(temp) {
      console.log(temp);
      this.form = temp;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form .el-form--label-center .el-form--inline {
  .el-form-item__content {
    display: flex;
    .el-date-editor {
      .el-input__inner {
        width: 20%;
      }
    }
  }
}

/deep/.el-form-item {
  width: 100%;
}
/deep/.el-form-item label {
  text-align:right;
}
.item2 {
  width: 105%;
}
.item {
  width: 108.5%;
}
.detail {
  padding: 20px;
  .el-col {
    padding: 8px 0;
  }
}
</style>
